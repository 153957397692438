<template>
    <el-container>
        <el-header class="header flex-center">
            <!-- <div>LOGO</div> -->
            <div class="logo"></div>
        </el-header>
        <el-container>
            <el-aside width="30%" class="middle mt-6 menu flex-end1">
                <div class="menu">
                    <div class="title">Vendor Repository</div>
                    <div
                        :class="type == 1 ? 'step1 back' : 'step1'"
                        :style="{
                            borderBottom:
                                showTwo != 1 ? '1px solid #707070' : 'none',
                        }"
                        @click="changeStep(1)"
                    >
                        Step 1 – Business Information
                    </div>
                    <div
                        v-if="showTwo == 1"
                        :class="type == 2 ? 'step2 back' : 'step2'"
                        @click="changeStep(2)"
                    >
                        Step 2 – Capability Review – Pre-Audit / RFQ
                    </div>
                </div>
            </el-aside>
            <el-main class="mt-6" width="70%">
                <div class="content">
                    <div class="name">
                        {{ type == 1 ? "Vendor" : "Factory" }}
                    </div>
                    <div v-if="type == 1" class="mt-6">
                        <Step1></Step1>
                    </div>
                    <div v-if="type == 2 && showTwo == 1">
                        <Step2></Step2>
                    </div>
                </div>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
import Step1 from "../components/Step1.vue";
import Step2 from "../components/Step2.vue";
export default {
    name: "Index",
    components: {
        Step1,
        Step2,
    },
    data() {
        return {
            type: 1,
            showTwo: null,
        };
    },
    created() {
        let data = JSON.parse(localStorage.getItem("dataList"));
        this.showTwo = data.isNeedStepTwo;
    },

    methods: {
        changeStep(type) {
            this.type = type;
        },
    },
};
</script>

<style>
.container {
}

.el-header {
    height: 100px;
}

.el-aside {
    height: calc(100vh - 200px);
}

.el-main {
    padding: 0;
    height: calc(100vh - 200px);
}

.header {
    width: 100%;
    height: 140px !important;
    /* background: #2ec9c3; */
    background: #5c5c5c;
    opacity: 1;
    line-height: 100px;
}

.logo {
    height: 140px;
    background: url("../assets/logo.png") 50% 100% no-repeat;
}

.header > div:first-child {
    width: 30%;
    text-align: center;
    line-height: 100px;
}

.header > div:nth-child(n + 1) {
    margin-right: 84px;
}

.menu {
    width: 33.885%;
    padding-right: 75px;
    box-sizing: border-box;
}

.menu > div {
    width: 350px;
    height: 50px;
    line-height: 50px;
    padding-left: 25px;
    box-sizing: border-box;
    font-weight: 400;
}

.menu .title {
    font-size: 18px;
    color: #ffffff;
    /* border: 1px solid #2ec9c3;
  background: #2ec9c3; */
    border: 1px solid #5c5c5c;
    background: #5c5c5c;
}

.menu .step1,
.menu .step2 {
    font-size: 14px;
    color: #4b5050;
    border: 1px solid #707070;
    border-top: none;
    background: #ffffff;
    cursor: pointer;
}

/* 	.menu .step1 {
		border-bottom: none;
	} */

.menu .back {
    background: rgb(92 92 92 / 30%);
}
</style>
